import React from 'react';
import { FlexContainer,dark, Wrapper, footerelement, navlink, orange, background3, Container, FlexBoxRow } from '../styles/globalstyles';
import Image from './image';
import { Link } from 'gatsby';
import { gql, useQuery } from "@apollo/client";
import { slugify } from './global-functions';
import {css} from '@emotion/react';



const BLOG = gql`
{
blogs(stage: PUBLISHED, orderBy: datum_DESC, first: 3) {
  titel 
  id
  }
}`

export const Footer = () => {
    const {data} = useQuery(BLOG)
    
    return (
      <Wrapper background={background3}> 
          <Container>
               <FlexContainer direction="column" css={css` align-items: center; padding-bottom: 0 !important; padding-top: 4em !important; max-width: 100vw !important; margin-left: 0 !important; margin-right: 0 !important; `} >
               <FlexBoxRow direction="row" justify="space-between" align="flex-start" css={css`width: 100%; align-items: flex-start !important; flex-wrap: wrap; padding-bottom: 2em;`}>
                    <div css={footerelement}>
                         <div css={css`width: 9em; height: auto; margin-bottom: 1.6em;`}>
                              <Image image="logofull" />
                         </div>
                    </div>
                    <div css={footerelement}>
                         <h6 css={css`color: ${dark} !important; margin-bottom: 0.6rem; margin-top: 1.2rem;`}>Inhalte</h6>
                         <Link to="/beratung"css={navlink} >Unser Service</Link> 
                         <Link to="/akademie"css={navlink} > Akademie</Link> 
                         <Link to="/team" css={navlink}> Team</Link> 
                         <Link to="/wissenswertes" css={navlink}> Wissenswertes</Link> 
                         <Link to="/kooperationen" css={navlink}> Kooperationen</Link> 
                         <Link to="/kontakt" css={navlink}> Kontakt</Link> 
                    </div>
                    <div css={footerelement}>
                         <h6 css={css`color: ${dark} !important; margin-bottom: 0.6rem; margin-top: 1.2rem;`}>Aktuelles</h6>
                         {(data) && 
                         data.blogs.map((value, index) => (
                              <Link css={navlink}  key={index} to={"/wissenswertes/" + slugify(value.titel)}>
                                   {value.titel}
                              </Link>
                              )
                         )
                         }
                         
                    </div>
                    <div css={footerelement}>
                         <h6 css={css`color: ${dark} !important; margin-bottom: 0.6rem; margin-top: 1.2rem;`}>Kontakt</h6>
                         <p> Proceed GmbH & Co KG <br />
                         Salzburger Weg 10 <br />
                         79111 Freiburg </p>
                         <p> mk@proceed.gmbh <br />
                         0761 / 897 61 83 </p>
                    </div>
                    </FlexBoxRow >
                    <div css={{background: orange, overflow: "hidden", width: "100vw", textAlign: "center", padding: "0.5em"}}>
                    <h6 css={css`color: white !important; `}>© {new Date().getFullYear()} Proceed Gmbh & Co KG</h6>
                    </div>
          </FlexContainer>
          </Container>
     </Wrapper>

)}