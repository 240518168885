import React from "react";
import { ApolloProvider } from '@apollo/client';
import { client } from './src/apollo/client';
import Layout from "./src/components/layout.js";
import EventContextProvider from "./src/provider/event-context-provider";
export const wrapPageElement = ({ element, props }) => {
  return (
    <ApolloProvider client={client}>
      <Layout {...props}>
        {element}
      </Layout>
    </ApolloProvider>
 )};

 export const wrapRootElement = ({ element }) => (
  <EventContextProvider>
    {element}
  </EventContextProvider>
)