import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import {css} from '@emotion/react';



const Icons = (props) => {
  const data = useStaticQuery(graphql`
    query {
      board: file(relativePath: { eq: "icons/icon_board.png" }) {
        childImageSharp {
          fluid(maxWidth: 64, grayscale: false, quality: 80) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      chem: file(relativePath: { eq: "icons/icon_chem.png" }) {
        childImageSharp {
          fluid(maxWidth: 64, grayscale: false, quality: 80) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      clock: file(relativePath: { eq: "icons/icon_clock.png" }) {
        childImageSharp {
          fluid(maxWidth: 64, grayscale: false, quality: 80) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      form: file(relativePath: { eq: "icons/icon_form.png" }) {
        childImageSharp {
          fluid(maxWidth: 64, grayscale: false, quality: 80) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      ideal: file(relativePath: { eq: "icons/Icon_Ideal.png" }) {
        childImageSharp {
          fluid(maxWidth: 64, grayscale: false, quality: 80) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      model: file(relativePath: { eq: "icons/icon_model.png" }) {
        childImageSharp {
          fluid(maxWidth: 64, grayscale: false, quality: 80) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      puzzle: file(relativePath: { eq: "icons/icon_puzzle.png" }) {
        childImageSharp {
          fluid(maxWidth: 64, grayscale: false, quality: 80) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      blog: file(relativePath: { eq: "icons/icon_blog.png" }) {
        childImageSharp {
          fluid(maxWidth: 64, grayscale: false, quality: 80) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }, 
      kontakt: file(relativePath: { eq: "icons/Icon_Kontakt.png" }) {
        childImageSharp {
          fluid(maxWidth: 64, grayscale: false, quality: 80) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }, 
      kontakt_shadow: file(relativePath: {eq: "grafiken/kontakt_shadow.png"}) {
        childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
                ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      methoden: file(relativePath: {eq: "icons/Icon_Methoden.png"}) {
        childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
                ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      puzzle_shadow: file(relativePath: {eq: "grafiken/board_shadow.png"}) {
        childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
                ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      board_shadow: file(relativePath: {eq: "grafiken/puzzle_shadow.png"}) {
        childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
                ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
    }
  `)
  if      (props.icon === "board")  return  <Img alt="Board Icon" fluid={data.board.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" css={css`width:100%; height: 100%; `} />
  else if (props.icon === "chem")  return  <Img alt="Chem Icon" fluid={data.chem.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" css={css`width: 100%; height: 100%;`} />
  else if (props.icon === "clock") return <Img alt="Clock Icon" fluid={data.clock.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%; `} />
  else if (props.icon === "form") return <Img alt="Form Icon" fluid={data.form.childImageSharp.fluid} objectFit="contain" css={css`height: 100%; width: 100%;`} />
  else if (props.icon === "ideal") return <Img alt="Ideal Icon" fluid={data.ideal.childImageSharp.fluid} objectFit="contain" css={css`height: 100%; width: 100%;`} />
  else if (props.icon === "model") return <Img alt="Model Icon" fluid={data.model.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.icon === "puzzle") return <Img alt="Puzzle Icon" fluid={data.puzzle.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.icon === "blog") return <Img alt="Blog Icon" fluid={data.blog.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.icon === "kontakt_shadow") return <Img alt="Kontakt Icon" fluid={data.kontakt_shadow.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.icon === "board_shadow") return <Img alt="Board Icon" fluid={data.board_shadow.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.icon === "puzzle_shadow") return <Img alt="Puzzle Icon" fluid={data.puzzle_shadow.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.icon === "kontakt") return <Img alt="kontakt Icon" fluid={data.kontakt.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.icon === "methoden") return <Img alt="Methoden Icon" fluid={data.methoden.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  
  else return
}

export default Icons
