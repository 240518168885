import React, { useState } from 'react'
import EventContext from './eventContext'

const EventContextProvider = ({children}) => {
    const [veranstaltungen, setVeranstaltungen] = useState([])
    const vrAddHandler = (id) => {
        let newArray = [...veranstaltungen]
        if (veranstaltungen.includes(id)) return

        else {
            newArray.push(id)
            setVeranstaltungen(newArray)
        }
    }
    const vrAddAllHandler = (ids) => {
        let newArray = [...ids]
        setVeranstaltungen(newArray)

    }
    const vrRemoveAllHandler = () => {
        let newArray = []
        setVeranstaltungen(newArray)

    }
    const vrRemoveHandler = (id) => {
        let newArray = [...veranstaltungen]
        let index = newArray.findIndex(element => element === id)

        newArray.splice(index, 1)
        setVeranstaltungen(newArray)
    }
    const vrCheck = (id) => {
        let newArray = veranstaltungen
        return (newArray.includes(id))
    }
    return (
        <EventContext.Provider
        value={{
            veranstaltungen,
            vrAddHandler: vrAddHandler,
            vrAddAllHandler: vrAddAllHandler,
            vrRemoveHandler: vrRemoveHandler,
            vrRemoveAllHandler: vrRemoveAllHandler,
            vrCheck: vrCheck
        }}
        >
         {children}   
        </EventContext.Provider>
    )
}

export default EventContextProvider
