import { dark, orange, white } from "../styles/globalstyles";

export const menuenterleft = {
    initial: { 
        x: "-100%",
        transition: {
            x: {duration: 0.4, delay: 0, ease: "easeIn"},
        }
     },
    visible: { 
        x: 0,
        transition: {
            x: {duration: 0.4, delay: 0, ease: "easeOut"},
        }
     },
    hidden: { 
        x: "-110%", 
        transition: {
            x: {duration: 0.4, delay: 0.1, ease: "easeOut"} ,
        }
  }
};
export const menuenterright = {
    initial: { 
        opacity: 1, 
        scale: 0.5,
        x: "100%",
        transition: {
            x: {duration: 0.3, delay: 0},
            opacity: {duration: 0.1, delay: 0}
        }
     },
    visible: { 
        opacity: 1, 
        scale: 1,
        x: 0,
        transition: {
            x: {duration: 0.3, delay: 0, ease: "easeOut"},
            scale: {duration: 0.3, delay: 0, ease: "easeOut"},
            opacity: {duration: 0.1, delay: 0} 
        }
     },
    hidden: { 
        opacity: 0,
        scale: 0.5,
        x: "100%", 
        transition: {
            x: {duration: 0.3, delay: 0},
            scale: {duration: 0.3, delay: 0},
            opacity: {duration: 0.2, delay: 0.5} 
        }
  }
};

export const sliderimage = {
    initial: {x: 50, width: "100%", height: "auto", opacity: 0},
    animate:  {x:0, width: "100%", height: "auto", opacity: 1, transition: {default: {duration: 0.4}}},
    exit:  {x:50, width: "100%", height: "auto", opacity: 0, transition: {default: {duration: 0.4}}}
}
export const slidertext = {
    initial: {y: 20, x:0, opacity: 0},
    animate:  {y:0, x:0, opacity: 1, transition: {default: {duration: 0.5}}},
    exit:  {y: 0, x: -20, opacity: 0, transition: {default: {duration: 0.4, delay: 0}}}
}

export const selector ={
    active: {
      color: white,
      background: orange,
      transition: {duration: 0.3}
    },
    inactive: {
      color: dark,
      background: white,
      transition: {duration: 0.3}
    }
  
  }

export const headVariants = {
    initial: {opacity: 1, y:-80, x: "-50%", transition: { duration: 0.4, opacity: {delay: 0.5}}},
    animate: {opacity: 1, y: 0, x: "-50%", transition: {ease: "easeOut", delay: 0.4 }}
  }