import React from "react"
import PropTypes from "prop-types"
import "./layout.css";
import "../fonts/fonts.css";
import Navigation from "./navigation"
import { Footer } from "./footer"
import ConsentCookie from "./consent";

const Layout = ({ children, pageContext }) => {
  return (
    <>
      <Navigation showMenu={(pageContext.isLandingPage === true) ? false : true } />
      <ConsentCookie />
        <main>
          {children}
        
        </main>
        <Footer />
    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
