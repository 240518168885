import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill";
import {css} from '@emotion/react';


const Image = (props) => {
  const data = useStaticQuery(graphql`
    query {
      startseite: file(relativePath: { eq: "grafiken/startseite_01_shadow.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, grayscale: false, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      beratung: file(relativePath: { eq: "header/header_set_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, grayscale: false, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      akademie: file(relativePath: { eq: "header/header_chairs.png" }) {
        childImageSharp {
          fluid(maxWidth: 10000, grayscale: false, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },

      logo: file(relativePath: { eq: "logo/Logo_Proceed.png" }) {
        childImageSharp {
          fluid(maxWidth: 48, quality: 75) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      logofull: file(relativePath: { eq: "logo/Logo_Proceed_full.png" }) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      network: file(relativePath: { eq: "header/header_network.png" }) {
        childImageSharp {
          fluid(maxWidth: 1400, grayscale: false, quality: 75) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      startseite_01: file(relativePath: {eq: "grafiken/startseite_01_shadow.png"}) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      startseite_02: file(relativePath: {eq: "grafiken/beratung_01_shadow.png"}) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      startseite_03: file(relativePath: {eq: "grafiken/beratung_04_shadow.png"}) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      startseite_04: file(relativePath: {eq: "grafiken/sprechblase_shadow.png"}) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      beratung_03: file(relativePath: {eq: "grafiken/beratung_03.png"}) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      puzzle: file(relativePath: {eq: "grafiken/puzzle_large_shadow.png"}) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 60) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      teambild: file(relativePath: {eq: "Gruppenbild.jpg"}) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      weissbuch: file(relativePath: {eq: "grafiken/Weißbuch.png"}) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      labyrinth: file(relativePath: {eq: "grafiken/01_Startseite_04_Keine-Uebersicht_gesch.png"}) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      blogimage: file(relativePath: { eq: "header/blog_header.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 75) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  if (props.image === "startseite")  return  <Img alt="Proceed Startseite Header" fluid={data.startseite.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" css={css`width: 100%; height: 100%;`} />
  else if (props.image === "beratung")  return  <Img alt="Proceed Beratung Header" fluid={data.beratung.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" css={css`width: 100%; height: 100%;`} />
  else if (props.image === "blog") return <Img alt="Proceed Blog Header" fluid={data.blogimage.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%; `} />
  else if (props.image === "logo") return <Img alt="Proceed Logo" fluid={data.logo.childImageSharp.fluid} objectFit="contain" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "logofull") return <Img alt="Proceed Logo full" fluid={data.logofull.childImageSharp.fluid} objectFit="contain" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "akademie") return <Img alt="Proceed Akademie Header" fluid={data.akademie.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "startseite_01") return <Img alt="Proceed Startseite Beratung" fluid={data.startseite_01.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "startseite_02") return <Img alt="Proceed Startseite Beratung" fluid={data.startseite_02.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "startseite_03") return <Img alt="Proceed Startseite Beratung" fluid={data.startseite_03.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "startseite_04") return <Img alt="Proceed Startseite Beratung" fluid={data.startseite_04.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "puzzle") return <Img alt="Proceed Kooperationen" fluid={data.puzzle.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "teambild") return <Img alt="Proceed Team" fluid={data.teambild.childImageSharp.fluid} objectFit="cover" objectPosition="50% 20%" css={css`width: 100%; height: 100%;`} />
  else if (props.image === "weissbuch") return <Img alt="Proceed Weißbuch" fluid={data.weissbuch.childImageSharp.fluid} objectFit="cover" objectPosition="50% 20%" css={css`width: 100%; height: 100%;`} />
  else if (props.image === "labyrinth") return <Img alt="Labyrinth" fluid={data.labyrinth.childImageSharp.fluid} objectFit="cover" objectPosition="50% 20%" css={css`width: 100%; height: 100%;`} />
  else if (props.image === "beratung_03") return <Img alt="Modell" fluid={data.beratung_03.childImageSharp.fluid} objectFit="cover" objectPosition="50% 20%" css={css`width: 100%; height: 100%;`} />
  
  else return
}

export default Image
