import React from 'react';
import styled from '@emotion/styled'
import { orange,  dark, white, grey, darkgrey, tintedgrey, tintedorange, eventcardsmall, FlexBoxRow, black, tintedblack } from '../styles/globalstyles';
import {motion} from 'framer-motion';
import { Link } from 'gatsby';
import Icons from './icons';
import {css} from '@emotion/react';

export const ButtonOrange = (props) =>{
    const BT =  styled.button({
    backgroundColor: orange,
    padding: "0.8em 3.2em",
    fontSize: "0.97em",
    fontWeight: 500,
    color: white,
    cursor: "pointer", 
    border: "none",
    borderRadius: "0.4em",
    textAlign: "center",
    display: "block",
    marginTop: "0.6em",
    marginBottom: "0.6em",
    fontFamily: 'Montserrat',
    transition: "all 0.2s ease-in",
    boxShadow: "4px 4px 10px -5px " + darkgrey,   
    overflow: "visible",
    width: "auto",
    [":hover"]: {backgroundColor: "#c44b04"}
    })
    return(
        <motion.div> <Link to={props.link}><BT>{props.text}</BT></Link></motion.div>
    )
}
export const ButtonOrangeNoLink = styled.button({
    backgroundColor: orange,
    padding: "0.8em 2.2em",
    fontSize: "0.97em",
    fontWeight: 500,
    color: white,
    cursor: "pointer", 
    border: "none",
    borderRadius: "0.4em",
    textAlign: "center",
    marginTop: "0.6em",
    marginBottom: "0.6em",
    fontFamily: 'Montserrat',
    transition: "all 0.2s ease-in",
    boxShadow: "4px 4px 10px -5px " + darkgrey,   
    overflow: "visible",
    [":hover"]: {backgroundColor: tintedorange}
    })
 
export const FormButtonOrange = (props) =>{
    const BT =  styled.button({
    backgroundColor: orange,
    padding: "0.8em 2.2em",
    fontSize: "0.97em",
    fontWeight: 500,
    color: white,
    cursor: "pointer", 
    border: "none",
    borderRadius: "0.4em",
    textAlign: "center",
    display: "block",
    marginTop: "1.4em",
    marginBottom: "0.6em",
    fontFamily: 'Montserrat',
    transition: "all 0.2s ease-in",
    boxShadow: "4px 4px 8px -4px " + dark, 
    overflow: "visible",
    [":hover"]: {backgroundColor: tintedorange}
    })
    return(
        <motion.div> <BT type="submit">{props.text}</BT></motion.div>
    )
}
export const FormButton = (props) =>{
    const BT =  styled.button({
    padding: "0.8em 2.2em",
    fontSize: "0.97em",
    fontWeight: 500,
    color: dark,
    cursor: "pointer", 
    border: "none",
    borderRadius: "0.4em",
    textAlign: "center",
    display: "block",
    marginTop: "0.6em",
    marginBottom: "0.6em",
    marginLeft: "0.4em",
    fontFamily: 'Montserrat',
    transition: "all 0.2s ease-in",
    boxShadow: "4px 4px 8px -4px " + dark, 
    overflow: "visible",
    width: "auto",
    backgroundColor: grey,
    [":hover"]: {backgroundColor: tintedgrey}
    })
    return(
        <motion.div > <BT>{props.text}</BT></motion.div>
    )
}
export const ButtonGrey = (props) =>  {
    const BT = styled.button({
        backgroundColor: grey,
        padding: "0.8em 2.2em",
        fontSize: "0.97em",
        fontWeight: 500,
        color: dark,
        cursor: "pointer", 
        border: "none",
        borderRadius: "0.4em",
        textAlign: "center",
        display: "block",
        marginTop: "0.6em",
        marginBottom: "0.6em",
        fontFamily: 'Montserrat',
        transition: "all 0.2s ease-in",
        boxShadow: "4px 4px 10px -5px " + darkgrey, 
        overflow: "visible",
        [":hover"]: {backgroundColor: tintedgrey}
        })
        return(
            <motion.div> <Link to={"/" + props.link}><BT>{props.text}</BT></Link></motion.div>
        )
}
export const ButtonDark = (props) =>  {
    const BT = styled.button({
        backgroundColor: black,
        padding: "0.8em 2.2em",
        fontSize: "0.97em",
        fontWeight: 500,
        color: white,
        cursor: "pointer", 
        border: "none",
        borderRadius: "0.4em",
        textAlign: "center",
        display: "block",
        marginTop: "0.6em",
        marginBottom: "0.6em",
        fontFamily: 'Montserrat',
        transition: "all 0.2s ease-in",
        boxShadow: "4px 4px 10px -5px " + darkgrey, 
        overflow: "visible",
        [":hover"]: {backgroundColor: tintedblack}
        })
        return(
            <motion.div> <Link to={"/" + props.link}><BT>{props.text}</BT></Link></motion.div>
        )
}
export const ButtonGreyNoLink = styled.button({
        backgroundColor: grey,
        padding: "0.8em 2.2em",
        fontSize: "0.97em",
        fontWeight: 500,
        color: dark,
        cursor: "pointer", 
        border: "none",
        borderRadius: "1em",
        textAlign: "center",
        display: "block",
        marginTop: "0.6em",
        marginBottom: "0.6em",
        marginLeft: "0.4em",
        fontFamily: 'Montserrat',
        transition: "all 0.2s ease-in",
        boxShadow: "4px 4px 10px -5px " + darkgrey, 
        overflow: "visible",
        [":hover"]: {backgroundColor: tintedgrey}
})
export const ButtonAkademie =() => {
    return(
    <motion.div css={eventcardsmall}> 
        <FlexBoxRow css={css`justify-content: flex-start !important;`}> 
        <div css={css`height: 2em; width: 2em; margin-right: 1.6em; `}>
            <Icons icon="board" />
        </div>
        <motion.div >
            <h6 >zur Proceed-Akademie</h6>
            <p css={css`color: ${dark} !important; margin-bottom: 0 !important;`}>Bringen Sie Ihr Unternehmen auf den Weg</p>
        </motion.div>
        </FlexBoxRow>
    </motion.div> 
    )
}
export const ButtonKontakt =() => {
    return(
    <motion.div css={eventcardsmall}> 
        <FlexBoxRow css={css`justify-content: flex-start !important;`}> 
        <div css={css`height: 2em; width: 2em; margin-right: 1.6em; `}>
            <Icons icon="form" />
        </div>
        <motion.div >
            <h6 >Kontakt</h6>
            <p css={css`color: ${dark} !important; margin-bottom: 0 !important;`}>Schreiben Sie uns</p>
        </motion.div>
        </FlexBoxRow>
    </motion.div> 
    )
}
