import {ApolloClient, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';


 export const client = new ApolloClient({
  uri: 'https://eu-central-1.cdn.hygraph.com/content/ck9ef7wlv03z701xjebrjg911/master',
  cache: new InMemoryCache(),
  fetch
});


